import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../home/v3/styledComponents';

const Container = styled.div`
  position: relative;
`;

const Img = styled.img`
  width: 100%;
  max-width: 100%;
`;

const Source = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: ${colors.white};
  font-family: Libre Baskerville;
  font-size: 12px;
  line-height: 18px;
`;

const Photo = ({ src, alt, source }) => (
  <Container>
    <Img src={src} alt={alt} />
    {source ? <Source>Source: {source}</Source> : null}
  </Container>
);

Photo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  source: PropTypes.string,
};

Photo.defaultProps = {
  source: null,
};

export default Photo;
