import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Photo from './Photo';
import { mobileThresholdPixels } from '../../home/v3/styledComponents';

const Container = styled.div`
  width: 440px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const BlockPhoto = ({ src, alt, source }) => (
  <Container>
    <Photo
      src={src.publicURL}
      alt={alt}
      source={source}
    />
  </Container>
);

BlockPhoto.propTypes = {
  src: PropTypes.shape({
    publicURL: PropTypes.string.isRequired,
  }).isRequired,
  alt: PropTypes.string.isRequired,
  source: PropTypes.string,
};

BlockPhoto.defaultProps = {
  source: null,
};

export default BlockPhoto;
