import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../../home/v3/styledComponents';

const Container = styled.div`
  position: relative;
  padding-top: 60px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 22px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const Quote = styled.div`
  width: 756px;
  margin: auto;
  font-family: Libre Baskerville;
  font-size: 32px;
  line-height: 53px;
  color: ${colors.navy};
  text-align: center;
  z-index: 1;
  position: relative;

  &:before,
  &:after {
    position: absolute;
    font-family: Libre Baskerville;
    font-weight: bold;
    font-size: 254px;
    line-height: 53px;
    color: ${colors.greenShadow};
    z-index: 0;
  }

  &:before {
    content: '“';
    top: 40px;
    left: -60px;
  }

  &:after {
    content: '”';
    bottom: -80px;
    right: -30px;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    width: unset;
    font-size: 18px;
    line-height: 39px;
    max-width: 213px;
    &:before,
    &:after {
      font-size: 165px;
    }
    &:before {
      top: 22px;
      left: -40px;
    }

    &:after {
      bottom: -100px;
      right: -20px;
    }
  }
`;

const Underline = styled.div`
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 10px;
  border-bottom: ${colors.green} 10px solid;
  margin-bottom: -8px;
  z-index: -1;

  @media (max-width: ${mobileThresholdPixels}) {
    border-bottom-width: 8px;
    margin-bottom: -10px;
  }
`;

const RowQuote = ({ quote }) => (
  <Container>
    <Quote>
      {quote
        .split('<u>')
        .map((quotePart, index) => index % 2 === 0
          ? (<span key={quotePart}>{quotePart}</span>)
          : (
            <span key={quotePart} style={{ position: 'relative' }}>
              {quotePart}
              <Underline />
            </span>
          ),
        )
      }
    </Quote>
  </Container>
);

RowQuote.propTypes = {
  quote: PropTypes.string.isRequired,
};

export default RowQuote;
