import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import { toFormattedText } from '../../services/formatting';
import { colors, margins, mobileThresholdPixels, fontSizes }
  from '../home/v2/styledComponents';

import quotes from '../../json/images/laRubrique/anecdote.quotes.png';
import SignericaEOT from '../../layouts/Signerica_Fat.eot';
import SignericaSVG from '../../layouts/Signerica_Fat.svg';
import SignericaTTF from '../../layouts/Signerica_Fat.ttf';
import SignericaWOFF from '../../layouts/Signerica_Fat.woff';

// eslint-disable-next-line no-unused-expressions
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Signerica";
    font-display: swap;
    src: url(${SignericaEOT}) format("eot");
    src: url(${SignericaSVG}) format("svg");
    src: url(${SignericaTTF}) format("truetype");
    src: url(${SignericaWOFF}) format("woff");
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  color: ${colors.navy};
  padding: ${margins.m} 0px;
  margin-bottom: ${margins.xl};
`;

const Img1 = styled.img`
  width: 250px;
  margin: -4px 96px 49px 69px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 140px;
    margin: 43px auto 29px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.lightGrey};
  width: 80vw;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    width: 90vw;
    margin: auto;
    padding: ${margins.m} 0px;
  }
`;

const TextContainer = styled.div`
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
    align-items: flex-start;
  }
`;

const P = styled.p`
  z-index: 2;
  font-family: Roboto;
  font-style: normal;
  text-align: left;
  font-size: 18px;
  line-height: 25px;
  margin-right: 63px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    width: auto;
    padding: 0px 20px;
    margin: 0px 0px 34px;
  }
`;

const Span2 = styled.span`
  font-family: Signerica;
  display: flex;
  flex-direction: row-reverse;
  margin-right: 20px;
  font-style: italic;
  font-size: 26px;
  margin-bottom: 37px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 0px 0px 20px;
    font-size: ${fontSizes.s};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: -55px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: -50px;
  }
`;

const Quotes = styled.img.attrs({ src: quotes })`
  width: 160px;
  margin-left: 320px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 60px;
    margin-left: 21px;
  }
`;

const Span1 = styled.span`
  font-family: Libre Baskerville;
  font-weight: 500;
  font-size: ${fontSizes.xl};
  font-weight: 500;
  margin-left: 80px;
  font-size: 48px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 22px;
    margin-left: 18px;
  }
`;

const Anecdote = styled.div`
  display: flex;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const LaRubriqueTemplateAnecdote = ({ tillisteName, tillisteWords, tillisteImg }) => (
  <Container>
    <Content>
      <TitleContainer>
        <Quotes />
        <Span1>L’Anecdote Tilliste</Span1>
      </TitleContainer>
      <Anecdote>
        <Img1 src={tillisteImg} />
        <TextContainer>
          <P>{toFormattedText(tillisteWords)}</P>
          <br />
          <Span2>{`By #MyTilliste ${tillisteName}`}</Span2>
        </TextContainer>
      </Anecdote>
    </Content>
    <GlobalStyle />
  </Container>
);

LaRubriqueTemplateAnecdote.propTypes = {
  tillisteName: PropTypes.string.isRequired,
  tillisteWords: PropTypes.string.isRequired,
  tillisteImg: PropTypes.string.isRequired,
};

export default LaRubriqueTemplateAnecdote;
