import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toFormattedBoldText } from '../../../services/formatting';
import { colors, mobileThresholdPixels } from '../../home/v3/styledComponents';

const Container = styled.div`
  font-size: 18px;
  line-height: 25px;
`;

const mobileTitlePrefixShift = 40;
const Title2 = styled.h2`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 32px;
  line-height: 45px;
  margin: 0px 0px 31px;
  position: relative;
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 23px;
    line-height: 32px;
  }
`;

const Title3 = styled(Title2.withComponent('h3'))`
  font-weight: normal;
  font-size: 26px;
  line-height: 33px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    line-height: 28px;
    left: ${mobileTitlePrefixShift}px;
    padding-right: ${mobileTitlePrefixShift}px;
  }
`;

const TitlePrefix = styled.span`
  position: absolute;
  left: -60px;

  @media (max-width: ${mobileThresholdPixels}) {
    left: -${mobileTitlePrefixShift}px;
  }
`;

const Paragraph = styled.p`
  margin: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const BlockText = ({ text, title, links }) => (
  <Container>
    {title && !title.prefix && (
      <Title2>{title.text}</Title2>
    )}
    {title && title.prefix && (
      <Title3>
        <TitlePrefix>{title.prefix}{' — '}</TitlePrefix>
        {title.text}
      </Title3>
    )}
    {text && (
      <Paragraph>
        {toFormattedBoldText(text, 500, links, { fontWeight: 500, color: colors.green })}
      </Paragraph>
    )}
  </Container>
);

BlockText.propTypes = {
  text: PropTypes.string,
  title: PropTypes.shape({
    text: PropTypes.string,
    prefix: PropTypes.string,
  }),
  links: PropTypes.arrayOf(PropTypes.string),
};

BlockText.defaultProps = {
  text: null,
  title: null,
  links: [],
};

export default BlockText;
