import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Block from './Block';
import { mobileThresholdPixels } from '../../home/v3/styledComponents';

const Container = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const Row2Columns = ({ blocks }) => (
  <Container>
    {blocks?.map((block, index) => <Block {...block} index={index} key={Block.getKey(block)} />)}
  </Container>
);

Row2Columns.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Row2Columns;
