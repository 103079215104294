import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../../home/v3/styledComponents';

const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;

  &:before,
  &:after {
    font-weight: lighter;
    font-size: 40px;
    color: ${colors.white};
    z-index: 0;
    content: '——';
    letter-spacing: -10px;
    height: 32px;
    border-bottom: ${colors.navy} 1px solid;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;

    &:before,
    &:after {
      margin: auto;
      width: 62px;
    }
    &:before {
      position: relative;
      bottom: 20px;
    }
    &:after {
      position: relative;
      bottom: 10px;
    }
  }
`;

const Text = styled.div`
  margin-left: 36px;
  margin-right: 26px;
  width: 390px;
  font-family: Libre Baskerville;
  font-size: 14px;
  line-height: 33px;
  color: ${colors.green};

  @media (max-width: ${mobileThresholdPixels}) {
    width: unset;
    margin: 0px 33px;
  }
`;

const Author = styled.span`
  color: ${colors.navy};
`;

const RowMadeBy = ({ author }) => (
  <Container>
    <Text>
      {'Cet article a été écrit avec plein de bonnes intentions pour nos clients grâce à '}
      <Author>{author}</Author>
    </Text>
  </Container>
);

RowMadeBy.propTypes = {
  author: PropTypes.string.isRequired,
};

export default RowMadeBy;
