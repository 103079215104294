import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../../home/v3/styledComponents';

const Highlight = styled.div`
  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 53px;
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Italic = styled.span`
  font-style: italic;
  color: ${colors.green};
`;

const BlockHighlight = ({ highlight }) => (
  <Highlight>
    {highlight
      .split('<i>')
      .map((part, index) => index % 2 === 0
        ? (<span key={part}>{part}</span>)
        : (<Italic key={part}>{part}</Italic>),
      )}
  </Highlight>
);

BlockHighlight.propTypes = {
  highlight: PropTypes.string.isRequired,
};

export default BlockHighlight;
