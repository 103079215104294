import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../../home/v3/styledComponents';
import { toFormattedBoldText } from '../../../services/formatting';

const Paragraph = styled.p`
  margin: 0px auto;
  font-size: 18px;
  line-height: 25px;
  width: 683px;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 44px);
    margin: 0px 22px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const TopBlock = ({ introduction, links }) => (
  <Paragraph>
    {toFormattedBoldText(introduction, 500, links, { color: colors.green })}
  </Paragraph>
);

TopBlock.propTypes = {
  introduction: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.string),
};

TopBlock.defaultProps = {
  links: [],
};

export default TopBlock;
