export function getSortedSlots(slots) {
  const newSlots = [...slots];
  return newSlots.sort((slotA, slotB) => new Date(slotA.begin) - new Date(slotB.begin));
}

export function getGroupedSlots(slots) {
  const sortedSlots = getSortedSlots(slots);
  let groupedSlots = [];
  groupedSlots = sortedSlots.reduce((accSlots, slot) => {
    if (accSlots.length === 0) return [{ id: 0, begin: slot.begin, end: slot.end }];
    const newAccSlots = [...accSlots];
    if (new Date(slot.begin).getTime() === new Date(accSlots[accSlots.length - 1].end).getTime()) {
      newAccSlots[accSlots.length - 1].end = slot.end;
    } else {
      newAccSlots.push({ id: newAccSlots.length, begin: slot.begin, end: slot.end });
    }
    return newAccSlots;
  }, []);
  return groupedSlots;
}

export function getGroupedSlotsPerDay(slots) {
  const groupedSlots = getGroupedSlots(slots);
  let groupedSlotsPerDay = [];
  groupedSlotsPerDay = groupedSlots.reduce((accSlots, slot) => {
    if (accSlots.length === 0) return [{ id: 0, slots: [{ begin: slot.begin, end: slot.end }] }];
    const newAccSlots = [...accSlots];
    if (new Date(slot.begin).toDateString() === new Date(accSlots[accSlots.length - 1].slots[0].begin).toDateString()) {
      newAccSlots[accSlots.length - 1].slots.push({ begin: slot.begin, end: slot.end });
    } else {
      newAccSlots.push({ id: newAccSlots.length, slots: [{ begin: slot.begin, end: slot.end }] });
    }
    return newAccSlots;
  }, []);
  return groupedSlotsPerDay;
}

export function getPrefilledSteps(category, cloth, decoPiece = '') {
  let selectedCategory = '';
  let selectedCloth = '';
  let selectedPiece = '';
  let selectedTypeOfWork = '';

  if (cloth === 'deco') {
    selectedCategory = 'deco';
    selectedPiece = decoPiece;
  } else {
    selectedCategory = 'clothes';
    selectedCloth = cloth;
  }

  switch (category) {
    case 'TRANSFORMATION': selectedTypeOfWork = 'transformation'; break;
    case 'RETOUCHE': selectedTypeOfWork = 'alteration'; break;
    case 'BRODERIE': selectedTypeOfWork = 'embroidery'; break;
    default: selectedTypeOfWork = '';
  }

  return { selectedCategory, selectedCloth, selectedPiece, selectedTypeOfWork };
}
