import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mobileThresholdPixels } from '../components/home/v3/styledComponents';

import LaRubriqueTemplate3TopBlock from '../components/LaRubrique/v3/TopBlock';
import LaRubriqueTemplate3Row from '../components/LaRubrique/v3/Row';
import { Title1 } from '../components/home/homeStyledComponents';
import Breadcrumb from '../components/LaRubrique/v3/Breadcrumb';

const Content = styled.div`
  max-width: 1064px;
  margin: auto;
`;

const RowsContainer = styled.div`
  margin-top: 100px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 60px;
  }
`;

const RowContainer = styled.div`
  margin-bottom: 100px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 60px;
  }
`;

export const Title = styled(Title1)`
  text-transform: uppercase;
  font-family: Libre Baskerville;
  max-width: 850px;
  margin: 0px auto 40px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px auto 20px;
  }
`;

const LaRubriquePostTemplate3 = ({ post }) => (
  <Content>
    {post.breadcrumb && <Breadcrumb itemListElement={post.breadcrumb} />}
    <Title>{post.mainTitle}</Title>
    <LaRubriqueTemplate3TopBlock {...post} />
    <RowsContainer>
      {post.rows?.map(row => (
        <RowContainer key={Math.random()}>
          <LaRubriqueTemplate3Row {...row} />
        </RowContainer>
      ))}
    </RowsContainer>
  </Content>
);

LaRubriquePostTemplate3.propTypes = {
  post: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default LaRubriquePostTemplate3;
