import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, margins, mobileThresholdPixels, fontSizes }
  from '../home/v2/styledComponents';

const Container = styled.div`
  text-align: center;
  color: ${colors.navy};
  padding: ${margins.m} 0px;
  margin-bottom: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 10px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGrey};
  margin: auto;
  padding: ${margins.m} 50px;
  max-width: 1180px;
  box-sizing: border-box;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    width: 90vw;
    padding: 0px;
  }
`;

const P = styled.p`
  z-index: 2;
  font-family: Roboto;
  font-style: normal;
  text-align: left;
  font-size: 18px;
  line-height: 25px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    margin: 23px 20px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const LaRubriqueTemplateMadeByBlock = ({ paragraph }) => (
  <Container>
    <Content>
      <P>{paragraph}</P>
    </Content>
  </Container>
);

LaRubriqueTemplateMadeByBlock.propTypes = {
  paragraph: PropTypes.string.isRequired,
};

export default LaRubriqueTemplateMadeByBlock;
