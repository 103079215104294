import React from 'react';
import PropTypes from 'prop-types';
import LaRubriquePostTemplate1 from './LaRubriquePostTemplate1';
import LaRubriquePostTemplate2 from './LaRubriquePostTemplate2';
import LaRubriquePostTemplate3 from './LaRubriquePostTemplate3';

const LaRubriquePostTemplate = ({ post }) => {
  switch (post.template) {
    case 3: return <LaRubriquePostTemplate3 post={post} />;
    case 2: return <LaRubriquePostTemplate2 post={post} />;
    default: return <LaRubriquePostTemplate1 post={post} />;
  }
};

LaRubriquePostTemplate.propTypes = {
  post: PropTypes.shape({
    template: PropTypes.number,
  }).isRequired,
};

export default LaRubriquePostTemplate;
