import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import withAppContext from '../../withAppContext';
import {
  toFormattedBoldText,
  toFormattedColoredText,
} from '../../services/formatting';
import {
  colors,
  margins,
  fontSizes,
  mobileThresholdPixels,
  contentWidth,
  Button,
} from '../../components/home/v3/styledComponents';
import routesMap from '../../Routes';

const Container = styled.div`
  color: ${colors.navy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${margins.m} 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: 0px;
  }
`;

const Content = styled.div`
  width: ${contentWidth}px;
  display: flex;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  justify-content: center;
  align-items: center;
  margin-bottom: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: ${({ noMobileMarginTop }) => (noMobileMarginTop ? 0 : 76)}px;
    margin-bottom: ${({ noMobileMarginTop }) =>
    noMobileMarginTop ? '33px' : `${margins.l}`};
    ${({ negativeMobileMarginBottom }) =>
    negativeMobileMarginBottom && 'margin-bottom: -80px;'};
    flex-direction: ${({ isMobileReversed }) =>
    isMobileReversed ? 'column-reverse' : 'column'};
    align-items: center;
    width: 100vw;
    ${({ mobileMarginBottom }) =>
    mobileMarginBottom && `margin-bottom: ${mobileMarginBottom};`}
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ margin }) => margin && `margin: ${margin};`}
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const CaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ isFlexEnd }) => isFlexEnd && 'align-items: flex-end;'}
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${({ marginMobile }) => marginMobile || '15px 0px 0px 12%'};
  }
`;

const CaptionText = styled.div`
  font-family: Roboto;
  font-size: ${({ isTitle }) => (isTitle ? 18 : 14)}px;
  line-height: ${({ isTitle }) => (isTitle ? 25 : 20)}px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 10px;
    line-height: 14px;
  }
`;

const Img = styled.img`
  @media (max-width: ${mobileThresholdPixels}) {
    width: ${({ isMobileFull }) => (isMobileFull ? 100 : 90)}vw;
    object-fit: cover;
    margin: 10px auto 0px;
  }
`;

const TextContainer = styled.div`
  flex: 1;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90vw;
    ${({ noMobileMarginTop }) => !noMobileMarginTop && 'margin-top: 38px;'}
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
  }
`;

const PContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ isFull }) => (isFull ? 'center' : 'flex-end')};
  ${({ isFull }) =>
    isFull &&
    `
    width: fit-content;
    margin: auto;
  `}
`;

const P = styled.div`
  display: flex;
  font-size: 18px;
  line-height: 25px;
  flex-direction: ${prop => (prop.directionColumn ? 'column' : 'row')};
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGrey};
  padding: ${({ padding }) => padding || '50px'};
  margin: ${prop => (prop.marginAuto ? `${margins.m} auto` : margins.m)};
  ${prop => prop.margin && `margin: ${prop.margin};`}
  max-width: ${({ isFull }) => (!isFull ? '800' : '1080')}px;
  ${({ isFull }) => isFull && 'box-sizing: content-box;'}
  ${({ textAlignCenter }) => textAlignCenter && 'text-align: center;'}

  @media (max-width: ${mobileThresholdPixels}) {
    height: auto;
    padding: 25px 20px;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
    ${({ isMobileTitleInsideBlock }) =>
    isMobileTitleInsideBlock && 'padding-top: 80px;'}
  }
`;

const ParagraphTitle = styled.span`
  align-self: flex-start;
  font-size: ${({ isCentered }) =>
    isCentered ? `${fontSizes.x3l}` : `${fontSizes.x2l}`};
  font-weight: 500;
  color: ${prop => (prop.yellow ? colors.yellow : colors.navy)};
  margin: ${prop => prop.margin || '0px 0px -50px 60px'};
  ${prop => prop.smallLeftMargin && 'margin-left: 25px'};
  z-index: 1;
  ${({ isCentered }) => isCentered && 'text-align: center;'};
  ${({ isDesktopTitleHidden }) => isDesktopTitleHidden && 'display:none;'};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${({ isCentered }) =>
    isCentered ? '0px 0px 33px' : '0px 0px -15px 20px'};
    font-size: ${fontSizes.xl};
    ${({ isCentered }) => isCentered && 'align-self: center; font-size: 20px;'};
    ${({ isMobileTitleCentered }) =>
    isMobileTitleCentered &&
      `
      margin: 0px 0px 28px 0px;
      align-self: center;
      text-align: center;
      font-size: 20px;
    `};
    ${({ isMobileTitleInsideBlock }) =>
    isMobileTitleInsideBlock &&
      `
      font-size: 15px;
      margin-bottom: -60px;
    `};
    ${({ isDesktopTitleHidden }) => isDesktopTitleHidden && 'display:block;'};
    ${({ isMobileTitleHidden }) => isMobileTitleHidden && 'display:none;'};
  }
`;

const Ol = styled.ol`
  padding-inline-start: 20px;
`;

const VideoContainer = styled.div`
  ${({ margin }) => margin && `margin: ${margin};`}
`;

const IFrame = styled.iframe`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const ButtonContainer = styled.div`
  width: fit-content;
  margin-top: 30px;
  @media (max-width: ${mobileThresholdPixels}) {
    align-self: center;
  }
`;

const ImgText = styled.div`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: ${({ fontSize }) => fontSize};
  color: ${colors.navy};
  position: absolute;
  margin: ${({ margin }) => margin};
`;

const ImagesRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const Block = (
  title,
  text,
  titleMargin,
  textMargin,
  textPadding,
  links,
  isMobileTitleInsideBlock,
  isMobileTitleCentered,
  isDesktopTitleHidden,
  isMobileTitleHidden,
  isTitle1Centered,
  isText1Centered,
) => (
  <PContainer isFull={!title || !text}>
    {title && (
      <ParagraphTitle
        margin={titleMargin}
        isCentered={!text || isTitle1Centered}
        isMobileTitleInsideBlock={isMobileTitleInsideBlock}
        isMobileTitleCentered={isMobileTitleCentered}
        isDesktopTitleHidden={isDesktopTitleHidden}
        isMobileTitleHidden={isMobileTitleHidden}
      >
        {toFormattedColoredText(title, 'yellow')}
      </ParagraphTitle>
    )}
    {text && (
      <P
        margin={textMargin}
        padding={textPadding}
        isFull={!title}
        isMobileTitleInsideBlock={isMobileTitleInsideBlock}
        textAlignCenter={isText1Centered}
      >
        <span>
          {toFormattedBoldText(text, 500, links, { fontWeight: '500' })}
        </span>
      </P>
    )}
  </PContainer>
);

const AdviceBlock = (list, closingText) => (
  <PContainer>
    <ParagraphTitle yellow smallLeftMargin>
      Conseil dépannage ...
    </ParagraphTitle>
    <P directionColumn marginAuto>
      <Ol>
        {list.map(step => (
          <li key={step}> {step}</li>
        ))}
      </Ol>
      <span>{closingText}</span>
    </P>
  </PContainer>
);

const LaRubriqueTemplate2MiddleBlockLongVersion = ({
  rows,
  adviceBlock,
  lastRow,
  context: { isMobile },
}) => (
  <Container>
    {rows.map((row) => {
      const hasImage = !!row?.image?.srcMobile || !!row?.image?.src;
      return (
        <Content
          key={`${row.isDesktopTitleHidden ? '1' : '2'} ${row.title1 || row.text1}`}
          reverse={!row.textIsRight}
          noMobileMarginTop={!row.text1 || !hasImage}
          negativeMobileMarginBottom={row.title1 && !row.text1 && !hasImage}
          isMobileReversed={row.isMobileReversed}
          mobileMarginBottom={row.contentMobileMarginBottom}
        >
          {(row?.image?.src || (isMobile && row?.image?.srcMobile)) && (
            <ImageContainer margin={row.image.margin}>
              <Img
                src={
                  isMobile && row.image?.srcMobile
                    ? row.image.srcMobile.publicURL
                    : row.image.src.publicURL
                }
                alt={row.image.alt}
                isMobileFull={row.image.isMobileFull}
              />
              {!!row?.image?.text && (
                <div>
                  <ImgText
                    fontSize={
                      isMobile
                        ? row.image.text.fontSizeMobile
                        : row.image.text.fontSize
                    }
                    margin={
                      isMobile
                        ? row.image.text.marginMobile
                        : row.image.text.margin
                    }
                  >
                    {row.image.text.label}
                  </ImgText>
                </div>
              )}
              {row?.image?.caption && (
                <CaptionContainer
                  margin={row.image.caption.margin}
                  marginMobile={row.image.caption.marginMobile}
                  isFlexEnd={row.image.caption.isFlexEnd}
                >
                  <CaptionText isTitle>
                    {toFormattedBoldText(row.image.caption.title, 500)}
                  </CaptionText>
                  {row.image.caption.text && (
                    <CaptionText>
                      {toFormattedBoldText(row.image.caption.text, 500)}
                    </CaptionText>
                  )}
                  {row.image.button && (
                    <ButtonContainer>
                      <Link
                        to={routesMap.Step1Summary.url}
                        state={{
                          prefilledCloth: row.image.button.prefilledStepCloth,
                        }}
                      >
                        <Button navy>{row.image.button.label}</Button>
                      </Link>
                    </ButtonContainer>
                  )}
                </CaptionContainer>
              )}
            </ImageContainer>
          )}
          {row?.video?.src && (
            <VideoContainer
              margin={isMobile ? row.video.mobileMargin : row.video.margin}
            >
              <IFrame
                width={isMobile ? row.video.mobileWidth : row.video.width}
                height={isMobile ? row.video.mobileHeight : row.video.height}
                src={row.video.src}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullScreen
              />
            </VideoContainer>
          )}
          {(row.title1 || row.text1) && (
            <TextContainer
              isFull={!row.title1 || !row.text1}
              noMobileMarginTop={!row?.image?.src && !row?.image?.srcMobile}
              mobileMargin={row.mobileTextMargin}
            >
              {Block(
                isMobile && row.title1Mobile ? row.title1Mobile : row.title1,
                row.text1,
                row.title1Margin,
                row.textMargin,
                row.textPadding,
                row.text1Links,
                row.isMobileTitleInsideBlock,
                row.isMobileTitleCentered,
                row.isDesktopTitleHidden,
                row.isMobileTitleHidden,
                row.isTitle1Centered,
                row.text1IsCentered,
              )}
            </TextContainer>
          )}
          {row.imagesRow && (
            <ImagesRow>
              {row.imagesRow.map(({ src, srcMobile, alt, margin, caption }) => (
                <ImageContainer margin={margin} key={src.publicURL}>
                  <Img
                    src={isMobile ? srcMobile.publicURL : src.publicURL}
                    alt={alt}
                  />
                  {caption && (
                    <CaptionContainer
                      margin={caption.margin}
                      marginMobile={caption.marginMobile}
                    >
                      <CaptionText isTitle>
                        {toFormattedBoldText(caption.title, 500)}
                      </CaptionText>
                      {caption.text && (
                        <CaptionText>
                          {toFormattedBoldText(caption.text, 500)}
                        </CaptionText>
                      )}
                    </CaptionContainer>
                  )}
                </ImageContainer>
              ))}
            </ImagesRow>
          )}
        </Content>
      );
    })}
    {adviceBlock && (
      <Content>
        {AdviceBlock(adviceBlock.adviceList, adviceBlock.closingText)}
      </Content>
    )}
    {lastRow && (
      <Content>
        <Img src={lastRow.image.src.publicURL} alt={lastRow.image.alt} />
        <TextContainer>{Block('', lastRow.text)}</TextContainer>
      </Content>
    )}
  </Container>
);

LaRubriqueTemplate2MiddleBlockLongVersion.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        src: PropTypes.shape({ publicURL: PropTypes.string }),
        srcMobile: PropTypes.shape({ publicURL: PropTypes.string }),
        margin: PropTypes.string,
        alt: PropTypes.string,
        caption: PropTypes.shape({
          title: PropTypes.string,
          text: PropTypes.string,
          margin: PropTypes.string,
          marginMobile: PropTypes.string,
          isFlexEnd: PropTypes.bool,
        }),
        prefilledStepCloth: PropTypes.string,
        isMobileFull: PropTypes.bool,
        text: PropTypes.shape({
          label: PropTypes.string,
          fontSize: PropTypes.string,
          fontSizeMobile: PropTypes.string,
          margin: PropTypes.string,
          marginMobile: PropTypes.string,
        }),
      }),
      video: PropTypes.shape({
        src: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
        margin: PropTypes.string,
        mobileWidth: PropTypes.string,
        mobileHeight: PropTypes.string,
        mobileMargin: PropTypes.string,
      }),
      textMargin: PropTypes.string,
      textPadding: PropTypes.string,
      title1: PropTypes.string,
      title1Mobile: PropTypes.string,
      title1Margin: PropTypes.string,
      title1MarginBottomMobile: PropTypes.string,
      isTitle1Centered: PropTypes.bool,
      contentMobileMarginBottom: PropTypes.string,
      text1: PropTypes.string,
      text1Links: PropTypes.arrayOf(PropTypes.string),
      text1IsCentered: PropTypes.bool,
      textIsRight: PropTypes.bool,
      isMobileReversed: PropTypes.bool,
      isMobileTitleCentered: PropTypes.bool,
      isDesktopTitleHidden: PropTypes.bool,
      isMobileTitleHidden: PropTypes.bool,
      isMobileTitleInsideBlock: PropTypes.bool,
      mobileTextMargin: PropTypes.string,
      imagesRow: PropTypes.arrayOf(
        PropTypes.shape({
          src: PropTypes.shape({ publicURL: PropTypes.string }),
          srcMobile: PropTypes.shape({ publicURL: PropTypes.string }),
          alt: PropTypes.string,
          caption: PropTypes.shape({
            title: PropTypes.string,
            text: PropTypes.string,
            margin: PropTypes.string,
            marginMobile: PropTypes.string,
          }),
          margin: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  adviceBlock: PropTypes.shape({
    adviceList: PropTypes.arrayOf(PropTypes.string.isRequired),
    closingText: PropTypes.string.isRequired,
  }),
  lastRow: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }),
      alt: PropTypes.string.isRequired,
    }),
    text: PropTypes.string.isRequired,
  }),
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(LaRubriqueTemplate2MiddleBlockLongVersion);
