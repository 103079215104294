import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mobileThresholdPixels } from '../../home/v3/styledComponents';

const Photo = styled.img`
  width: 100%;
  height: 600px;
  object-fit: cover;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100% - 44px);
    height: 337px;
    margin: 0px 22px;
  }
`;

const RowPhoto = ({ photo: { src, alt } }) => (
  <Photo src={src.publicURL} alt={alt} />
);

RowPhoto.propTypes = {
  photo: PropTypes.shape({
    src: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
};

export default RowPhoto;
