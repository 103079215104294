import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BlockText from './BlockText';
import { mobileThresholdPixels } from '../../home/v3/styledComponents';

const Container = styled.div`
  width: 846px;
  margin: auto;

  @media (max-width: ${mobileThresholdPixels}) {
    width: unset;
    margin: 0px 22px;
  }
`;

const RowParagraph = ({ paragraph }) => (
  <Container>
    <BlockText {...paragraph} />
  </Container>
);

RowParagraph.propTypes = {
  paragraph: PropTypes.shape({}).isRequired,
};

export default RowParagraph;
