import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../../home/v3/styledComponents';
import { toFormattedBoldText } from '../../../services/formatting';

const Conclusion = styled.div`
  font-family: Libre Baskerville;
  font-size: 22px;
  line-height: 40px;
  color: ${colors.navy};
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    line-height: 33px;
    margin: 0px 22px;
  }
`;

const RowConclusion = ({ conclusion, links }) => (
  <Conclusion>
    {toFormattedBoldText(conclusion, 'bold', links, { color: colors.green })}
  </Conclusion>
);

RowConclusion.propTypes = {
  conclusion: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.string),
};

RowConclusion.defaultProps = {
  links: [],
};

export default RowConclusion;
